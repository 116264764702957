import HTTP from './HTTP';

const URL_BASE = 'user/';

export async function status() {
  const response = await HTTP.get(URL_BASE + 'status/');
  return response;
}

export async function VerifyUserAccount(email) {
  const response = await HTTP.get(URL_BASE + 'verify-user-account/', {
    params: {
      email,
    },
  });
  return response;
}

export async function CreateUser(dataUser, userId) {
  const response = await HTTP.post(URL_BASE + 'create-user/' + userId, dataUser);
  return response;
}

export async function GetUsers() {
  const response = await HTTP.get(URL_BASE + 'get-users/');
  return response;
}

export async function DeleteMenyUsers(userIds) {
  const response = await HTTP.post(URL_BASE + 'delete-meny-users/', { userIds: userIds || [] });
  return response;
}

export async function ChangePrivilege(userId, profil) {
  const response = await HTTP.put(URL_BASE + 'change-privilege/', { userId, profil });
  return response;
}

export async function UpdateUser(data) {
  const response = await HTTP.put(URL_BASE + 'update-user/', data);
  return response;
}

export async function GetUser() {
  const response = await HTTP.get(URL_BASE + 'get-user/');
  return response;
}

export async function GetUserDoctor() {
  const response = await HTTP.get(URL_BASE + 'get-user-doctor/');
  return response;
}

export async function GetUserPatient() {
  const response = await HTTP.get(URL_BASE + 'get-user-patient/');
  return response;
}
