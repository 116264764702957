<template>
    <!-- eslint-disable -->
    <div class="columns is-centered is-marginless">
        <div
        class="column has-background-primary columns is-flex-direction-column
            is-centered is-marginless is-hidden-mobile is-4 lhs"
        >
    </div>
    <div class="column is-8 rhs">
        <div>
            <h1 class="is-size-2 has-text-weight-medium has-text-primary has-text-centered">
            {{ settings.organization && settings.organization.name ?
            settings.organization.name : 'Telemedicine'  }}
            </h1>
        </div>

        <div class="is-flex is-align-items-center"
         style="min-height: 70vh;">
            <div class="container">
                <slot />
            </div>
        </div>
        
        </div>
    </div>
</template>

<script>
/*eslint-disable */ 
import { mapGetters } from 'vuex';

export default {
    name: "PlainLayout",
    computed: {
        ...mapGetters(['settings'])
    }
};
</script>

<style scoped>
    .lhs {
        height: 100vh;
        position: fixed;
        left: 0
    }
    .rhs {
        margin-left: 33.33333%
    }
    @media screen and (max-width: 768px) {
        .rhs {
            margin-left: 0
        }
    }
</style>

