export function emailValidation(email) {
  const REGEX_EMAIL = new RegExp('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\\.[a-zA-Z]{2,}$$');
  return REGEX_EMAIL.test(email);
}

export function validation(
  infoData,
  infoError,
  time,
  fiels = [
    'email',
    'firstName',
    'lastName',
    'mobileNumber',
  ],
) {
  let valid = true;
  if (fiels.includes('email') && !emailValidation(infoData.email)) {
    valid = false;
    infoError.email = 'Email address is not valid';
    setTimeout(() => { infoError.email = ''; }, time);
  }
  if (fiels.includes('firstName') && !infoData.firstName) {
    valid = false;
    infoError.firstName = 'First name is not valid';
    setTimeout(() => { infoError.firstName = ''; }, time);
  }
  if (fiels.includes('lastName') && !infoData.lastName) {
    valid = false;
    infoError.lastName = 'Last name is not valid';
    setTimeout(() => { infoError.lastName = ''; }, time);
  }
  if (fiels.includes('mobileNumber') && !/^\+234[0-9]{8,10}/g.test(infoData.mobileNumber)) {
    valid = false;
    infoError.mobileNumber = 'Mobile Number is not valid (ex: +234XXXXXXXX)';
    setTimeout(() => { infoError.mobileNumber = ''; }, time);
  }
  return valid;
}

export function validationAppointmentPatient(infoData, infoError, time) {
  let valid = true;
  if (!infoData.startAt) {
    valid = false;
    infoError.startAt = 'Date entered is invalid';
    setTimeout(() => { infoError.startAt = ''; }, time);
  }
  if (!infoData.patientDesc) {
    valid = false;
    infoError.patientDesc = 'The description of the appointment is invalid';
    setTimeout(() => { infoError.patientDesc = ''; }, time);
  }
  return valid;
}

export function validationAppointmentAdmin(infoData, infoError, time) {
  let valid = true;
  if (!infoData.startAt) {
    valid = false;
    infoError.startAt = 'Date entered is invalid';
    setTimeout(() => { infoError.startAt = ''; }, time);
  }
  if (!infoData.patientDesc) {
    valid = false;
    infoError.patientDesc = 'The description of the appointment is invalid';
    setTimeout(() => { infoError.patientDesc = ''; }, time);
  }
  if (!infoData.patientId) {
    valid = false;
    infoError.patientId = 'ID patient is invalid';
    setTimeout(() => { infoError.patientId = ''; }, time);
  }
  if (!infoData.patientName) {
    valid = false;
    infoError.patientName = 'Patient name is invalid';
    setTimeout(() => { infoError.patientName = ''; }, time);
  }
  if (!infoData.patientPhone) {
    valid = false;
    infoError.patientPhone = 'Patient phone is invalid';
    setTimeout(() => { infoError.patientPhone = ''; }, time);
  }
  if (!infoData.doctorId) {
    valid = false;
    infoError.doctorId = 'ID doctor is invalid';
    setTimeout(() => { infoError.doctorId = ''; }, time);
  }
  if (!infoData.doctorName) {
    valid = false;
    infoError.doctorName = 'Doctor name is invalid';
    setTimeout(() => { infoError.doctorName = ''; }, time);
  }
  if (!infoData.doctorPhone) {
    valid = false;
    infoError.doctorPhone = 'Doctor phone is invalid';
    setTimeout(() => { infoError.doctorPhone = ''; }, time);
  }
  return valid;
}
